.title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 0 0 16px 0;
  color: #1a1a1a;
}

.description {
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  color: #666;
  margin: 0 0 24px 0;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.keepButton {
  width: 100%;
  max-width: 400px;
  height: 56px;
  padding: 10px 16px;
  border-radius: 33px;
  border: none;
  background: #ff68b4;
  font-size: 16px;
  color: #fff;
  font-weight: 590;
  cursor: pointer;
}

.cancelButton {
  width: 100%;
  max-width: 400px;
  height: 56px;
  padding: 10px 16px;
  border-radius: 33px;
  border: none;
  background: #ffeff4;
  font-size: 16px;
  color: #ff68b4;
  font-weight: 590;
  cursor: pointer;
}
