.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100dvw;
  max-width: 600px;
  min-height: 100dvh;
  margin: 0 auto;
  background-color: #fff;
}
