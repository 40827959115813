.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  position: relative;
  width: 90px;
  height: 90px;
}

.circular {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.staticCircle {
  stroke: #E2E8F0;
  stroke-linecap: round;
}

.path {
  stroke: #2E72FD;
  stroke-linecap: round;
  stroke-dasharray: 170, 251.2;
  animation: rotate 2s linear infinite;
  transform-origin: center;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
