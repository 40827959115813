.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3001;
}

.modal {
  background-color: white;
  border-radius: 36px;
  width: calc(100% - 80px);
  max-width: 500px;
  padding: 20px;
  animation: slideUp 0.3s ease-out;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  color: #8e8e8e;
  cursor: pointer;
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.textarea {
  min-height: 150px;
  padding: 12px;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  font-size: 14px;
  resize: none;
  outline: none;
  transition: border-color 0.3s;
  white-space: pre-wrap;
}

.textarea:focus {
  border-color: #a8a8a8;
}

.textarea::placeholder {
  font-family: inherit;
  color: #8e8e8e;
  font-size: 14px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.counter {
  color: #8e8e8e;
  font-size: 12px;
}

.submitButton {
  padding: 8px 20px;
  color: white;
  border: none;
  border-radius: 33px;
  background: #ff68b4;
  font-size: 16px;
  color: #fff;
  font-weight: 590;
  cursor: pointer;
}

.submitButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
