.container {
  padding: 20px 20px 5px;
  width: calc(100% - 40px);
  max-width: 600px;
  background: #fff;
  /* height: calc(100dvh - 40px); */
  margin: 0 auto;
}

.divider {
  height: 12px;
  background-color: #fafafa;
  margin: 0 -20px; /* Компенсируем padding контейнера */
  width: 100vw; /* На всю ширину viewport */
  max-width: 600px;
}

.section:not(:last-child) {
  margin-bottom: 32px;
}

.sectionTitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  margin: 10px 0 24px;
}

.status {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
  padding: 0 0 18px;
  border-bottom: 1px solid #e3e3e3;
}

.statusItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activeStatus {
  color: #22c55e;
}

.inactiveStatus {
  color: #ff3b30;
}

.statusDescription {
  font-size: 12px;
}

.paymentInfo {
  margin-bottom: 16px;
  padding: 0 0 18px;
}

.paymentDetails {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.paymentTitle {
  color: #4a4a4a;
}

.paymentDate {
  font-weight: 500;
}

.price {
  color: #7f7f7f;
}

.manageButton {
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  height: 56px;
  padding: 10px 16px;
  border-radius: 33px;
  border: none;
  background: #ffeff4;
  font-size: 16px;
  color: #ff68b4;
  font-weight: 590;
  cursor: pointer;
  display: block;
}

.toggleItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.toggleTitle {
  width: 100%;
  max-width: 228px;
}

/* Toggle Switch Styles */
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5e7eb;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ff69b4;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.linkItem {
  display: flex;
  align-items: center;
  padding: 12px 0;
  cursor: pointer;
  gap: 10px;

  > svg {
    width: 40px;
    height: 40px;
  }

  &.first {
    padding-top: 0;
  }

  &.last{
    padding-bottom: 0;
  }
}

.linkIcon {
  width: 32px;
  height: 32px;
  background: #ffe4ef;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.arrow {
  margin-left: auto;
  color: #9ca3af;
}
