/* BeautyQuestions.module.css */
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.questionBlock {
  border: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.questionTitle {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #333;
}

.answersContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;
}

.answerItem {
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 24px;
}

/* Стилизация чекбоксов и радио кнопок */
.input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid #333;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  background-color: white;
}

.input[type='checkbox']:checked {
  background-color: #ff69b4;
  border-color: #ff69b4;
}

.input[type='checkbox']:checked::after {
  content: '✓';
  position: absolute;
  color: white;
  font-size: 14px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.input[type='radio'] {
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid #333;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  background-color: white;
}

.input[type='radio']:checked {
  border: 1px solid #ff69b4;
}

.input[type='radio']:checked::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #ff69b4;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.input[type='checkbox'],
.input[type='radio'] {
  margin: 0;
  flex-shrink: 0;
}

.label {
  font-size: 16px;
  color: #444;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 1.2;
  min-height: 24px;
}

.otherInput {
  width: 100%;
  min-height: 40px;
  padding: 8px 12px;
  margin-top: 8px;
  border: 2px solid #ff69b4;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.2s ease;
  resize: none;
  overflow: hidden;
  box-sizing: border-box;
  font-family: inherit;
  line-height: 1.5;
}

.otherInput:focus {
  outline: none;
  border-color: #ff1493;
  box-shadow: 0 0 0 2px rgba(255, 20, 147, 0.1);
}

.error {
  color: #dc3545;
  font-size: 14px;
  margin-top: 8px;
}

.submitButton {
  width: 100%;
  max-width: 400px;
  height: 56px;
  padding: 10px 16px;
  border-radius: 33px;
  border: none;
  background: #ff68b4;
  font-size: 16px;
  color: #fff;
  font-weight: 590;
  cursor: pointer;
  margin: 0 auto;
}

.submitButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 20, 147, 0.3);
}

.submitButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.input[type='checkbox']:checked + .label,
.input[type='radio']:checked + .label {
  color: #ff1493;
  font-weight: 500;
}

/* Адаптивные стили */
@media (max-width: 600px) {
  .container {
    padding: 16px;
  }

  .questionTitle {
    font-size: 16px;
  }

  .label {
    font-size: 14px;
  }

  .submitButton {
    padding: 10px 20px;
    font-size: 14px;
  }
}
