.paymentHistoryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: auto;
  padding: 0 20px;
}

.content {
  width: 100%;
  max-width: 600px;
  padding: 20px;
}

.title {
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0;
  color: #000000;
}

.paymentList {
  display: flex;
  flex-direction: column;
}

.paymentItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #e5e5e5;
}

.date {
  font-size: 16px;
  color: #000000;
}

.amount {
  font-size: 16px;
  color: #7F7F7F;
}

.amountAndCheck{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}