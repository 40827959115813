body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: #4a4a4a;
  overflow-y: auto;
  max-height: 100dvh;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
}
html {
  overflow: hidden;
}
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 15px 15px 5px;
  min-height: 100dvh;
  background-color: #fff0f5; /* Light pink background */
}

.App {
  opacity: 1;
  background-color: transparent;
  /* transition: opacity 1.2s ease; */
}

.fadeOut {
  background-color: #1d2733;
  /* opacity: 0; */
}

* {
  scrollbar-width: none !important;
  -webkit-tap-highlight-color: transparent !important;
}

*::-webkit-scrollbar {
  display: none !important;
}
