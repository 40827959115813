.story{
display: flex;
flex-direction: column;
justify-content: center;
}


.storyComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.storyCircle {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background: linear-gradient(45deg, #f09433, #bc1888);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  position: relative;
}

.storyImage {
  width: 62px;
  height: 62px;
  border-radius: 50%;
}

.storyText {
  font-size: 12px;
  text-align: center;
}

.storiesLoader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    45deg,
    #ffe6f2,
    #ffc0cb,
    #ffb6c1,
    #ff69b4,
    #ffb6c1,
    #ffc0cb,
    #ffe6f2
  );
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;
  /* width: 62px; */
  /* height: 62px; */
  border-radius: 50%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Можно добавить пульсирующий эффект для дополнительной визуальной привлекательности */
.loaderComponent {
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.7;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

