.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8e8e8e;
  font-weight: 600;
  padding: 5px 20px 10px;
  height: 34px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.text {
  padding: 0;
  margin: 0;
  font-size: 6px;
  white-space: pre-line;
  text-align: center;
  font-weight: 400;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.button {
  border: none;
  background: none;
  color: #8e8e8e;
  text-decoration: underline;
  cursor: pointer;
  font-size: 8px;
  font-weight: 600;
}
