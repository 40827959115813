.profileHeader {
  margin-bottom: 20px;
}

.headerHeader {
  display: flex;
  margin-bottom: 20px;
}

.profilePictureButton {
  border: none;
  background: transparent;
  margin-right: 20px;
  cursor: pointer;
  padding: 0;
}

.infoInfo {
  flex: 1;
  flex-direction: column;
}

.profilePicture {
  width: 86px;
  height: 86px;
  border-radius: 50%;
}

.profileInfo {
  flex: 1;
}

.profileNameWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.profileNameBadge {
  display: flex;
  align-items: center;
}

.profileName {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}

.verifiedBadge {
  width: 18px;
  height: 18px;
  margin-left: 5px;
}

.shareButton {
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;

  .shareIcon {
    color: #ff69b4;
  }
}

.profileStats {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 15px;
}

.stat {
  text-align: center;
}

.statValue {
  font-weight: 600;
  font-size: 18px;
}

.statTitle {
  font-size: 16px;
}

@media (max-width: 400px) {
  .statTitle {
    font-size: 14px;
  }
}

.socialIcons {
  display: flex;
  gap: 20px;
}

.socialIcon {
  color: #ff69b4;
  font-size: 24px;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.profileBio {
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 1.5;
  margin-top: 0px;
  white-space: pre-line;
}
