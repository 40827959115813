.subscriptionHeader {
  margin-top: 20px;
  height: 200px;
  width: 100%;
  max-width: 600px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.logoBack {
  height: 180px;
  width: auto;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 108px;
  height: auto;
}

.subscriptionContent {
  flex: 1;
  width: 100%;
  max-width: 600px;
  display: flex;  
  flex-direction: column;
  align-items: center;
}

.contentTitle {
  display: flex;
  gap: 5px;
  width: min-content;
  margin: 0px auto 20px;
}

.contentTitle h2 {
  font-size: 32px;
  font-weight: 500;
  margin: 0;
}

.contentTitle h2:last-child {
  color: #FF68B4;
}

.featuresList {
  width: calc(100% - 88px);
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  border-radius: 32px;
  border: 1px solid #DEDEDE
}

.subscriptionFooter {
  width: calc(100% - 40px);
  max-width: 600px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.subscribeButton {
  width: 100%;
  max-width: 400px;
  height: 56px;
  padding: 10px 16px;
  border-radius: 33px;
  border: none;
  background: #ff68b4;
  font-size: 16px;
  color: #fff;
  font-weight: 590;
  cursor: pointer;
}

.chatButton {
  width: 100%;
  max-width: 400px;
  height: 56px;
  padding: 10px 16px;
  border-radius: 33px;
  border: none;
  background: #ffeff4;
  font-size: 16px;
  color: #ff68b4;
  font-weight: 590;
  cursor: pointer;
}

.errorPopupTitle {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 0 0 16px 0;
  color: #1a1a1a;
}

.errorPopupDescription {
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  color: #666;
  margin: 0 0 24px 0;
}

.errorPopupButtons {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.keepButton {
  width: 100%;
  max-width: 400px;
  height: 56px;
  padding: 10px 16px;
  border-radius: 33px;
  border: none;
  background: #ff68b4;
  font-size: 16px;
  color: #fff;
  font-weight: 590;
  cursor: pointer;
}

.cancelButton {
  width: 100%;
  max-width: 400px;
  height: 56px;
  padding: 10px 16px;
  border-radius: 33px;
  border: none;
  background: #ffeff4;
  font-size: 16px;
  color: #ff68b4;
  font-weight: 590;
  cursor: pointer;
}
