.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  opacity: 1;
  transition: opacity 0.3s ease-out;
  overflow: hidden;
}

.fadeOut {
  opacity: 0;
}

.slider {
  position: absolute;
  top: 9px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 34px;
  height: 3px;
  border-radius: 15px;
  background-color: #d9d9d9;
}

.drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  z-index: 101;
  transform: translateY(0);
  transition: transform 0.3s ease-out;
  animation: slideUp 0.3s ease-out;
  height: auto;
  max-height: 96vh;
}

.slideDown {
  transform: translateY(100%);
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.container {
  margin: 0 auto;
  width: calc(100% - 40px);
  max-width: 600px;
}

.content {
  padding: 20px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px 0;
  text-align: left;
}

.menuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  cursor: pointer;
}

.menuItem:last-of-type {
  border-bottom: none;
}

.arrow {
  color: #999;
}

.cancelText {
  color: #ff3b30;
}

.backButtonContainer {
  display: flex;
  justify-content: center;
}

.backButton {
  width: 100%;
  max-width: 400px;
  height: 56px;
  padding: 10px 16px;
  border-radius: 33px;
  border: none;
  background: #ffeff4;
  font-size: 16px;
  color: #ff68b4;
  font-weight: 590;
  cursor: pointer;
  margin-top: 30px;
}
