.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background-color: #fff5f9;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
  max-width: 400px;
  height: 95dvh;
  position: relative;
  animation: popup-appear 0.3s ease-out;
  margin: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.popupHeader {
  padding: 20px;
  background-color: #fff5f9;
  position: sticky;
  top: 0;
  z-index: 2;
}

.popupContentWrapper {
  flex: 1;
  overflow-y: auto;
  padding: 0 20px;
}

.popupFooter {
  padding: 20px;
  background-color: #fff5f9;
  position: sticky;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@keyframes popup-appear {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.popupTitle {
  color: #ff69b4;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.popupContent {
  color: #666;
  line-height: 1.5;
}

.popupContent h3 {
  color: #ff69b4;
  font-size: 16px;
  font-weight: 600;
  margin: 20px 0 10px;
}

.popupContent p {
  font-size: 14px;
}

.popupButton {
  width: 100%;
  max-width: 400px;
  height: 56px;
  padding: 10px 16px;
  border-radius: 33px;
  border: none;
  background: #ff68b4;
  font-size: 16px;
  color: #fff;
  font-weight: 590;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancelButton {
  background: #ffeff4;
  color: #ff68b4;
}

.popupButtonDisabled {
  background: #ffb8db;
  cursor: not-allowed;
}

.popupList {
  list-style-type: none;
  padding-left: 20px;
  margin-top: -10px;
}

.popupList li {
  position: relative;
  padding-left: 0px;
  margin-bottom: 4px;
  font-size: 14px;
}

.popupList li:before {
  content: '-';
  position: absolute;
  left: -10px;
  color: #666;
}

.tableWrapper {
  overflow-x: auto;
  margin: 15px -20px;
  padding: 0 20px;
}

.popupTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 8px;
  max-width: 600px;
}

.popupTable th,
.popupTable td {
  border: 1px solid #ffd1e7;
  padding: 12px;
  text-align: left;
  vertical-align: top;
}

.popupTable th {
  background-color: #fff0f7;
  color: #ff69b4;
  font-weight: 600;
  white-space: nowrap;
}

/* Стили для чекбоксов */
.checkboxContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ff68b4;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
}

.checkbox:checked {
  background-color: #ff68b4;
}

.checkbox:checked::after {
  content: '';
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  position: absolute;
  transform: rotate(45deg);
  top: 0;
}

.checkboxText {
  font-size: 10px;
  color: #666;
  line-height: 1.3;
  padding-top: 2px;
}

.linkButton {
  background: none;
  border: none;
  padding: 0;
  font-size: 12px;
  color: #ff68b4;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}

.popupButtons {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

