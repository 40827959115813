/* Popup.module.css */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

.fadeOut {
  opacity: 0;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  background: white;
  border-radius: 32px;
  padding: 20px;
  z-index: 201;
  transition: transform 0.3s ease-out;
  animation: scaleUp 0.3s ease-out;
}

.scaleDown {
  transform: translate(-50%, -50%) scale(0.9);
  opacity: 0;
}

@keyframes scaleUp {
  from {
    transform: translate(-50%, -50%) scale(0.9);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}
