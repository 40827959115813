.featureItem {
  display: flex;
  align-items: center;
  gap: 12px;
  align-items: flex-start;
}

.featureCheck {
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    padding-bottom: 4px;
  }
}

.featureContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.featureTitle {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  height: 21px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.arrow {
  font-size: 10px;
  transition: transform 0.3s ease;
  margin-left: 8px;
}

.arrow.expanded {
  transform: rotate(180deg);
}

.featureDescription {
  color: #4b5563;
  font-size: 13px;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease, margin 0.3s ease;
  opacity: 0;
}

.featureDescription.expanded {
  max-height: 100px;
  opacity: 1;
}
