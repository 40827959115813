/* Контейнер для всех историй */
.storiesContainer {
  display: flex;
  padding: 10px 0;
  gap: 10px;
  margin-bottom: 10px;
}

/* Модальное окно для просмотра истории */
.storyModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 2000;
  overflow: hidden;
}

/* Шапка истории с градиентом */
.storyHeader {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  top: 0;
  z-index: 2001;
  box-sizing: border-box;
}

/* Информация о пользователе */
.storyUserInfo {
  display: flex;
  align-items: center;
}

.storyUserAvatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.storyUsername {
  color: #fff;
  font-weight: 600;
}

/* Кнопка закрытия */
.storyClose {
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
}

/* Навигация по историям (прогресс-бар) */
.storyNavigation {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  height: 3px;
  display: flex;
  z-index: 2002;
  padding: 0 10px;
}

/* Элемент прогресс-бара */
.storyNavItem {
  flex: 1;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0 2px;
  position: relative;
  overflow: hidden;
}

/* Заполнение для предыдущих историй */
.storyNavItem.viewed {
  background-color: #fff;
}

/* Анимация заполнения активного элемента */
.storyNavItem.active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform-origin: left;
  animation: progress 3s linear forwards;
}

/* Пауза анимации при взаимодействии */
.storyNavItem.active.paused::after {
  animation-play-state: paused;
}

/* Keyframes для анимации прогресса */
@keyframes progress {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

/* Контент истории */
.storyContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

/* Изображение истории */
.storyFullImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: border-radius 0.3s ease;
}

/* Футер истории с градиентом */
.storyFooter {
  width: 100%;
  padding: 20px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  bottom: 0;
  z-index: 2001;
  box-sizing: border-box;
}

/* Описание истории */
.storyDescription {
  color: #fff;
  margin-bottom: 15px;
}

/* Цена */
.storyPrice {
  color: #ff69b4;
  font-weight: 600;
  font-size: 18px;
}

/* Кнопка действия */
.storyCta {
  width: 100%;
  max-width: 400px;
  height: 56px;
  padding: 10px 16px;
  border-radius: 33px;
  border: none;
  background: #ff68b4;
  font-size: 16px;
  color: #fff;
  font-weight: 590;
  cursor: pointer;
}

/* Модальное окно покупки */
.purchaseModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
}

.modalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

/* Кнопки модального окна */
.modalClose {
  background-color: #ff69b4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
}

.modalConfirm {
  background-color: #32cd32;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
}

.modalClose:hover,
.modalConfirm:hover {
  opacity: 0.8;
}

.ctaWrapper {
  display: flex;
  justify-content: center;
}
