@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

:root {
  --background-color: #ffffff;
  --text-color: #262626;
  --accent-color: #0095f6;
  --secondary-color: #dbdbdb;
  --tertiary-color: #fafafa;
}

.playScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--background-color);
  z-index: 1000;
  font-family: 'Roboto', sans-serif;
}

.version {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 0.8em;
  color: var(--text-color);
  opacity: 0.5;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
  gap: 20px;
}

.title {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--text-color);
  margin: 0;
  letter-spacing: 0.5px;
}

.description {
  margin: 0;
}

.haloImage {
  width: 150px;
  height: 150px;
  margin-bottom: 30px;
  border-radius: 50%;
  border: 2px solid var(--accent-color);
  object-fit: cover;
  box-shadow: 0 4px 12px rgba(0, 149, 246, 0.15);
}

.haloImage:hover {
  transform: scale(1.05);
}

.partnerLogo {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 85px;
  height: 85px;
  opacity: 0.7;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.partnerLogo:hover {
  opacity: 1;
  transform: translateX(-50%) scale(1.05);
}

.button {
  width: 100%;
  max-width: 400px;
  height: 56px;
  padding: 10px 16px;
  border-radius: 33px;
  border: 2px solid var(--accent-color);
  background: #ff68b4;
  font-size: 16px;
  color: #fff;
  font-weight: 590;
  cursor: pointer;
}
