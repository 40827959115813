.paidChatsModal {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  height: calc(100dvh - 49px);
  max-width: 600px;
  overflow-y: auto;
  margin: 0 auto;
}

.modalHeader {
  width: 100%;
  max-width: 600px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chatList {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 20px 5px;
}

.chatItem {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px; /* Добавлен отступ между чатами */
  background-color: #f9f9f9; /* Легкий фон для разделения блоков */
  border-radius: 10px; /* Закругление углов для каждого чата */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Легкая тень для разделения */
}

.chatItem:last-child {
  border-bottom: none;
  margin-bottom: 0;
  justify-content: flex-end;
}

.chatIcon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.chatInfo {
  flex: 1;
}

.chatName {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.chatDescription {
  font-size: 14px;
  color: #666;
}

.chatPrice {
  font-size: 16px;
  font-weight: bold;
  color: #ff69b4;
  margin-top: 10px;
  margin-bottom: 10px;
}

.joinButton {
  background-color: #ff69b4;
  color: #fff;
  border: none;
  padding: 12px 20px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  max-width: 30vh;
  align-self: flex-end; /* Сдвигает кнопку вправо */
}

.joinButton:hover {
  background-color: #ff1493;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #ff69b4;
  position: absolute;
  top: 20px;
  right: 20px;
}
