.product-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-modal-content {
  background-color: #fff;
  height: 100%;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Прокрутка для всего контента */
  padding-bottom: 0; /* Убираем здесь padding-bottom */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.product-modal-title {
  position: sticky;
  top: 0;
  font-size: 30px;
  margin: 0;
  background: #fff;
  padding: 5px 0;
}

.product-modal-close {
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
}

.product-modal-close:hover {
  color: #000;
}

.product-slider {
  position: relative;
  flex-shrink: 0; /* Чтобы изображения не сжимались */
  overflow: hidden;
  height: auto; /* Высота для корректного отображения изображений */
}

.product-slider-container {
  display: flex;
  transition: transform 0.3s ease;
}

.product-slider-image {
  width: 100%;
  height: auto;
  flex-shrink: 0;
}

.slider-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.slider-nav:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.slider-nav.prev {
  left: 10px;
}

.slider-nav.next {
  right: 10px;
}

.slider-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.slider-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.slider-dot.active {
  background-color: #ff69b4;
}

.product-info {
  flex-shrink: 0;
  padding: 20px;
  padding-bottom: 136px;
}

.product-comments-info {
  padding-bottom: 96px;
}

.product-stats {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  font-size: 14px;
  height: 28px;
  color: #262626;
}

.premium-icon-wrapper {
  width: fit-content;
  padding: 6.5px 10px;
  display: flex;
  align-items: center;
  padding: 6px 10px 6px 10px;
  gap: 4px;
  border-radius: 25px;
  background: #ff68b4;
  color: #fff;
  font-size: 12px;
}

.stat-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.stat-icon {
  margin-right: 5px;
  font-size: 24px;
}

@media (max-width: 400px) {
  .stat-item {
    margin-right: 10px;
  }
  .stat-icon {
    font-size: 20px;
  }
}

.stat-icon.liked {
  color: #ff69b4;
}

.also-liked {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-left: auto;
  font-size: 14px;
  color: #666;
}
.also-liked-avatars {
  display: flex;
  margin-left: 5px;
}

.also-liked-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: -8px;
  border: 2px solid #fff;
}

@media (max-width: 400px) {
  .also-liked {
    font-size: 13px;
  }
  .also-liked-avatar {
    width: 22px;
    height: 22px;
  }
}

.product-modal-description {
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  margin: 0 0 15px;
}

.product-buy-wrapper,
.product-сomments-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 18px 20px 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.product-modal-buy {
  width: 100%;
  max-width: 400px;
  height: 56px;
  padding: 10px 16px;
  border-radius: 33px;
  border: none;
  background: #ff68b4;
  font-size: 16px;
  color: #fff;
  font-weight: 590;
  cursor: pointer;
  margin: 0 auto;
}

.product-modal-chat {
  width: 100%;
  max-width: 400px;
  height: 56px;
  padding: 10px 16px;
  border-radius: 33px;
  border: none;
  background: #ffeff4;
  font-size: 16px;
  color: #ff68b4;
  font-weight: 590;
  cursor: pointer;
  margin: 0 auto;
}

@keyframes bounce-and-grow {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
}

.bounce {
  animation: bounce-and-grow 0.5s cubic-bezier(0.28, 0.84, 0.42, 1);
}

@media (max-width: 768px) {
  .product-modal-content {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  /* 
  .product-modal-title {
    font-size: 18px;
  } */

  .product-stats {
    height: 28px;
    font-size: 12px;
  }

  .product-modal-description {
    font-size: 14px;
  }

  .slider-nav {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .slider-nav {
    display: none;
  }
}

.loading-stats {
  opacity: 0.6;
  filter: blur(3px);
  pointer-events: none;
}

.loading-placeholder {
  width: 100%;
  height: 28px;
  background: rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.loading-bar {
  width: 30%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.05) 25%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0.05) 75%
  );
  animation: loading 1.5s infinite;
}

@keyframes loading {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(400%);
  }
}

.pdf-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 1000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.close-pdf {
  align-self: flex-end;
  padding: 8px 16px;
  background: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-pdf:hover {
  background: #d32f2f;
}
