.profileActionsContainer {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  gap: 10px;
}

.profileActions {
  display: flex;
  justify-content: space-between;
}

.profileButton {
  flex: 1;
  background-color: #ff69b4;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
  text-decoration: none;
}

@media (max-width: 384px) {
  .profileButton {
    font-size: 13px;
  }
}

@media (max-width: 364px) {
  .profileButton {
    font-size: 12px;
  }
}

.profileButton i {
  margin-right: 5px;
}

.profileButton:last-child {
  margin-right: 0;
}
