.productList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
}

/* Добавляем контейнер для списка */
.productListContainer {
  position: relative;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  min-height: calc(100dvh - 57px - 49px + 10px);
  transition: height 0.3s ease;
}
