.rpv-core__tooltip-body {
  display: none;
}

.rpv-default-layout__toolbar {
  background-color: transparent;
  border-bottom: none;
}

.rpv-default-layout__sidebar-headers {
  background: transparent;
}

.rpv-default-layout__container {
  border: none;
}

.rpv-page-navigation__current-page-input {
  margin: 0;
}

.rpv-core__textbox {
  text-align: center;
}

.rpv-page-navigation__current-page-input {
  width: 2.7rem;
}

.rpv-default-layout__sidebar--ltr {
  display: none;
}
