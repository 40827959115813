.toolbar {
  position: relative;
  background: #f8f9fa;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.toolbarContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  gap: 20px;
}

.toolbarContent > div {
  display: flex;
  gap: 5px;
  align-items: center;
}

.pagination {
  display: flex;
  justify-content: space-around;
  font-size: 12px;
}

.button {
  padding: 8px 15px;
  background: none;
  border: none;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 1000;
}
.viewerContainer {
  height: 100%;
  display: flex;
  width: 100%;
}

.searchSidebar {
  width: 30%;
  height: 100%;
}
