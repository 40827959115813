.commentsSection {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
}

.commentsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.commentsTitle {
  font-size: 18px;
  font-weight: 600;
  color: #262626;
  margin: 0;
}

.closeButton {
  background: none;
  border: none;
  color: #8e8e8e;
  font-size: 24px;
  line-height: 1;
  padding: 0;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.commentsList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.commentItem {
  padding: 12px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.commentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.username {
  font-weight: 600;
  color: #262626;
  font-size: 14px;
}

.timestamp {
  color: #8e8e8e;
  font-size: 12px;
}

.commentContent {
  color: #262626;
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
  white-space: pre-wrap;
}

.noComments {
  text-align: center;
  color: #8e8e8e;
  font-size: 20px;
}
.ownComment {
  background-color: rgba(255, 105, 184, 0.1);
}

