.contentToggle {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #dbdbdb;
  background-color: #fff0f5;
  padding: 0px 0 10px;
  z-index: 100;
  margin: 0;
}

/* Add fixed positioning styles */
.contentToggleFixed {
  position: fixed;
  top: 0;
  width: calc(100dvw - 30px);
}

.contentToggleButton {
  background: none;
  border: none;
  padding: 10px 10px 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #8e8e8e;
  position: relative;
  transition: color 0.3s ease;
}

.contentToggleButton.active {
  color: #ff69b4;
}

.contentToggleButton.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ff69b4;
}

/* Helper class for the placeholder element */
.placeholder {
  height: 1px;
  width: 100%;
  visibility: hidden;
}

/* Helper class for the spacer element */
.spacer {
  width: 100%;
}
