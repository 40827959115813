.productItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 2px;
  transition: box-shadow 0.3s ease;
  aspect-ratio: 1;
  overflow: hidden;
}

.productItem:hover {
  box-shadow: 0 4px 12px rgba(210, 17, 239, 0.1);
}

.productImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.productImage.loaded {
  opacity: 1;
}

.productImage:not(.loaded) {
  opacity: 0;
}

.productInfo {
  text-align: center;
}

.productLoader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productLoader:not(.loading) {
  background: linear-gradient(
    45deg,
    #ffe6f2,
    #ffc0cb,
    #ffb6c1,
    #ff69b4,
    #ffb6c1,
    #ffc0cb,
    #ffe6f2
  );
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;
}

.productLoader.loading {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(2px);
}

.loader {
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  animation: pulse 1.5s ease-in-out infinite;
}

.loader.loading {
  width: 40px;
  height: 40px;
  background: none;
  border: 4px solid rgba(255, 105, 180, 0.2);
  border-top-color: #ff69b4;
  animation: spin 1s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.7;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (min-width: 768px) {
  .productList {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .productList {
    grid-template-columns: repeat(4, 1fr);
  }
}

