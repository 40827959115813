@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

:root {
  --background-color: #FFFFFF;
  --text-color: #262626;
  --accent-color: #0095F6;
  --secondary-color: #DBDBDB;
  --tertiary-color: #FAFAFA;
}

.playScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--background-color);
  z-index: 1000;
  font-family: 'Roboto', sans-serif;
}

.version {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 0.8em;
  color: var(--text-color);
  opacity: 0.5;
}

.haloTitle {
  font-size: 2em;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 20px;
  letter-spacing: 0.5px;
}

.haloImage {
  width: 150px;
  height: 150px;
  margin-bottom: 30px;
  border-radius: 50%;
  border: 2px solid var(--accent-color);
  object-fit: cover;
  box-shadow: 0 4px 12px rgba(0, 149, 246, 0.15);
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.haloImage:hover {
  transform: scale(1.05);
}

.loadingContainer {
  width: 80%;
  max-width: 300px;
  margin-bottom: 20px;
}

.loadingBar {
  width: 100%;
  height: 4px;
  background: var(--secondary-color);
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}

.loadingProgress {
  width: 0%;
  height: 100%;
  background: var(--accent-color);
  border-radius: 2px;
  transition: width 0.5s ease;
  position: relative;
}

.loadingProgress::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(255,255,255,0) 0%,
    rgba(255,255,255,0.5) 50%,
    rgba(255,255,255,0) 100%
  );
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.loadingText {
  margin-top: 10px;
  font-size: 0.9em;
  font-weight: 500;
  color: var(--accent-color);
  text-align: center;
}

.partnerLogo {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 85px;
  height: 85px;
  opacity: 0.7;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.partnerLogo:hover {
  opacity: 1;
  transform: translateX(-50%) scale(1.05);
}